@media only screen and (max-width: 600px) {
    .service-detail .container { grid-gap: 45px; grid-template-columns: minmax(0, 1fr); }
    .service-detail .container .owner .naming { margin-top: 30px; }
    .services-carousel-wrapper .container .services-image-carousel { height: 400px; }
    .service-prolog .scroll-down { display: none; }
    .service-prolog .content > .wrapper { width: 100%; position: relative; z-index: 2; }
    .service-prolog .image { opacity: 0.5; }
    .service-process .wrapper .content .text { font-size: 14px; }
    .service-process .wrapper .content { max-width: 50%; }
    .services-carousel-wrapper .container { grid-template-columns: minmax(0, 1fr); }
    .services-carousel-wrapper .container .services-image-carousel { height: 300px; }
    .services-carousel-wrapper .container .services-image-carousel .swiper-wrapper .swiper-slide .image { height: 70%; }
    .service-process { @include safari-overflow; }
    .about-prolog .content .wrapper .expo { font-size: 16px; margin-left: 60px; }
    .about-prolog .content .wrapper .expo::before { width: 30px; right: calc(100% + 30px); }
    .about-prolog { height: unset!important; }
    .about-prolog .content .wrapper { padding: 60px 0!important; }
    .quote-field .container .quote { font-size: 26px; }
    .about-whatwedo .container { grid-template-columns: minmax(0, 1fr); }
    .about-whatwedo { height: unset!important; }
    .about-whatwedo .container .detail { height: 500px; transform: unset; }
    .about-whatwedo .container .detail .image { height: 75%; }
    .about-whatwedo .container .detail .context .title { font-size: 24px; }
    .about-whatwedo .container .text .title { font-size: 28px; }
    .about-whatwedo .container .detail .context .project-trigger .text { font-size: 14px; }
    .about-whatwedo .container .detail .context .project-trigger .wrapper { width: 60px; height: 60px; }
    .about-whatwedo .container .detail .context .project-trigger .wrapper .icon-wrapper, .about-whatwedo .container .detail .context .project-trigger .wrapper svg { width: 14px; height: 14px; }
    .reference-field .reference-list { grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .hero .scroll-down { display: none; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .title { font-size: 32px; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .expo { font-size: 18px; }
    .button-style--icon-edge-color span { font-size: 14px; }
    .hero .content .image-area .hero-image-carousel .swiper-wrapper .swiper-slide .image { height: 50%; opacity: 0.5; }
    .hero .content { grid-template-columns: minmax(0, 1fr); }
    .hero .content .image-area { @include absolute-element; width: 50%; left: unset; right: 0; }
    .hero .content .text-area .hero-content-carousel { width: 100%; }
    .index-about .container .text .title { font-size: 30px; }
    .index-about .container .text .expo { font-size: 16px; }
    .index-about .container .text .slogan-field { flex-direction: column; @include supports(gap, 20px, margin-top, 20px) }
    .index-products { margin-top: 30px!important; }
    .hero { height: unset!important; }
    .hero .content { padding: 60px 20px 90px; }
    .hero .content .text-area { padding: unset!important; }
    .service-process { display: flex; justify-content: center; align-items: center; }
    .service-process .wrapper { height: unset; width: 90%; }
    .service-process { height: 100vh!important; }
}