@media only screen and (max-width: 1024px) {
    .service-process .wrapper .content .text { font-size: 18px; }
    .service-detail .container { grid-gap: 120px; }
    .princibles-field .container { grid-template-columns: minmax(0, 1fr); grid-gap: 30px; }
    .princibles-field .container .item { max-width: unset; }
    .princibles-field .container::before, .princibles-field .container::after { display: none; content: unset; }
    .quote-field .container .quote { font-size: 32px; }
    .about-whatwedo .container .detail .image { height: 60%; }
    .about-whatwedo .container .detail .context .project-trigger .wrapper { height: 75px; width: 75px; }
    .about-whatwedo .container .detail .context .project-trigger .wrapper .icon-wrapper, .about-whatwedo .container .detail .context .project-trigger .wrapper svg { width: 20px; height: 20px; }
    .reference-field .reference-list .item { height: 150px!important; }
    .about-whatwedo .container .detail .context .title { font-size: 30px; }
    .about-whatwedo .container .text .title { font-size: 32px; }
    .reference-field .headline .sub-title { font-size: 38px; }
    .product-prolog .background .image:nth-child(2), .product-prolog .background .image:nth-child(3) { opacity: 0.5; height: 25%; }
    .product-prolog .background .image:nth-child(1) { height: 45%; }
    .product-prolog .content .title { font-size: 32px; }
    .product-prolog .content { justify-content: center; }
    .index-products .container .wrapper .content .context .reveal-products .button { display: none; }
    .index-products .container .wrapper .content .context .reveal-products .product-list { display: grid!important; }
    .index-products .container .wrapper .content .context .heading .title { font-size: 24px; }
}