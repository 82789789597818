.scroll-down {
    position: absolute;
    width: 120px;
    height: 120px;
    cursor: pointer;
    @include flex-element;
    &::before {
        content: '';
        position: absolute;
        height: 15px;
        width: 1px;
        background-color: $mariner;
        left: 50%;
        top: 25%;
        transform: translateX(-50%) translateY(-25%);
        transition: .45s ease all;
    }
    & > span {
        opacity: 0.5;
        color: $hippie-blue;
        display: block;
        text-align: center;
        letter-spacing: calc(12px * 7 / 100);
        transform: translateY(75%);
        line-height: 1.25;
        transition: .45s ease all;
    }
    .wave {
        @include absolute-element;
        transform: rotate(45deg);
        box-shadow: 0 0 0 1px rgba($hippie-blue, 0.2);
        transition: .45s ease box-shadow;
        span {
            @include absolute-element;
            display: block;
            box-shadow: 0 0 0 1px rgba($hippie-blue, 0.2);
            transition: .45s ease box-shadow;
        }
    }
    &:hover {
        &::before { transform: translateX(-50%) translateY(-25%) scaleY(0); }
        & > span { color: $mariner; opacity: 1; transform: translateY(0%) }
        .wave span { box-shadow: 0 0 0 1px $mariner; }
        .wave { box-shadow: 0 0 0 1px $mariner; }
    }
}