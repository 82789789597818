/* [------------------] Plugins [------------------] */
    @import '../../node_modules/swiper/swiper-bundle.min.css';
    @import '../../node_modules/@fancyapps/ui/dist/fancybox.css';

/* [------------------] Elements [------------------] */
    @import 'element/element';

/* [------------------] Layout [------------------] */
    @import 'layout/layout';

/* [------------------] Responsive [------------------] */
    @import 'responsive/max2560px';
    @import 'responsive/max1920px';
    @import 'responsive/max1680px';
    @import 'responsive/max1440px';
    @import 'responsive/max1366px';
    @import 'responsive/max1200px';
    @import 'responsive/max1024px';
    @import 'responsive/max900px';
    @import 'responsive/max768px';
    @import 'responsive/max600px';
    @import 'responsive/max480px';
    @import 'responsive/max425px';
    @import 'responsive/max375px';  