.about-prolog {
    height: 100vh;
    background-color: $ebony;
    position: relative;
    .background {
        @include safari-overflow;
        @include absolute-element;
        mix-blend-mode: exclusion;
        pointer-events: none;
        opacity: 0.05;
        img, video {
            @include image-fit;
        }
    }
    .content {
        height: 100%;
        display: grid;
        grid-template-columns: minmax(0, auto) minmax(0, 120px);
        grid-gap: 120px;
        .scroll-down {
            align-self: end;
            position: relative;
        }
        .wrapper {
            align-self: center;
            .title {
                color: white;
                line-height: 1.25;
            }
            .expo {
                margin-left: 390px;
                position: relative;
                color: $bali-hai;
                &::before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 345px;
                    right: calc(100% + 45px);
                    top: 15px;
                    background-color: $christine;
                }
            }
        }
    }
}

.about-whatwedo {
    @include safari-overflow;
    background-color: $ebony;
    .container {
        height: 100%;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
        grid-gap: 120px;
        align-items: center;
        background-color: $ebony;
        .text {
            .title {
                color: white;
                line-height: 1.1;
            }
            .expo {
                color: $bali-hai;
            }
        }
        .detail {
            height: 100%;
            @include flex-element;
            position: relative;
            transform: translateX(20%);
            .image {
                height: 80%;
                transform: rotate(45deg);
                mix-blend-mode: overlay;
                opacity: 0.5;
                position: relative;
                filter: brightness(60%);
                .wrapper {
                    @include safari-overflow;
                    @include absolute-element;
                    &::after {
                        @include pseudo-element;
                        background: radial-gradient(circle at 50% 50%, rgba($ebony, 0) 0%, rgba($ebony, 0.75) 100%);
                        mix-blend-mode: multiply;
                    }
                    img {
                        @include image-fit;
                        transform: scale(1.5) rotate(-45deg);
                    }
                }
            }
            .context {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                z-index: 2;
                @include flex-element;
                flex-direction: column;
                .title {
                    color: white;
                    line-height: 1.25;
                }
                .list {
                    .item {
                        display: block;
                        position: relative;
                        &::before {
                            content: '';
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            transform: translateY(150%) translateX(-150%) rotate(45deg);
                            background-color: $mariner;
                            right: 100%;
                            bottom: 100%;
                        }
                        & + .item {
                            margin-top: 20px;
                        }
                        .actual {
                            color: white;
                        }
                        .placeholder {
                            color: rgba(white, 0.75);
                        }
                    }
                }
                .project-trigger {
                    background-color: transparent;
                    border: unset;
                    cursor: pointer;
                    position: relative;
                    img { display: none; }
                    display: flex;
                    align-items: center;
                    .wrapper {
                        @include flex-element;
                        transforM: rotate(45deg);
                        width: 90px;
                        height: 90px;
                        &::after {
                            @include pseudo-element;
                            transform: scale(0.8);
                            background-color: #11212F;
                            z-index: 2;
                            box-shadow: 0 0 0 10px $ebony;
                            transition: .45s ease all;
                        }
                        &::before {
                            @include pseudo-element;
                            background: linear-gradient(90deg, $mariner 0%, rgba(#22384A, 0) 50%, rgba(#22384A, 0) 50%, $mariner 100%);
                            background-size: 200% 100%;
                            background-position: left center;
                            z-index: 1;
                            transition: .45s ease all;
                        }
                        .icon-wrapper {
                            transform: rotate(-45deg);
                            width: 26px;
                            height: 26px;
                            position: relative;
                            z-index: 3;
                        }
                        svg {
                            width: 26px;
                            height: 26px;
                            fill: $mariner;
                            transition: .3s ease fill;
                        }
                    }
                    .text {
                        margin-left: 30px;
                        letter-spacing: calc(16px * 12 / 100);
                        color: white;
                        display: block;
                        position: relative;
                        width: fit-content;
                        .actual {
                            color: $mariner;
                        }
                    }
                    &:hover {
                        .wrapper::before { background-position: right center; }
                        .wrapper::after { background-color: $mariner; }
                        svg { filL: white; }
                    }
                }
            }
        }
    }
}