.reference-field {
    .headline {
        text-align: center;
        margin: 0 auto;
        width: fit-content;
        .title {
            margin: 0 auto;
            position: relative;
            color: $bali-hai;
            letter-spacing: calc(18px * 22 / 100);
        }
        .sub-title {
            margin: 0 auto;
            position: relative;
            color: white;
            line-height: 1.25;
        }
    }
    .reference-list {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 45px 30px;
        transform: translateZ(0);
        .item {
            position: relative;
            display: block;
            border-style: solid;
            border-image-slice: 1;
            border-width: 1px;
            border-image-source: linear-gradient(180deg, $big-stone 0%, rgba($big-stone, 0) 100%);
            background: linear-gradient(180deg, $ebony 0%, lighten($ebony, 4%) 50%, lighten($ebony, 4%) 50%, $ebony 100%);
            background-size: 100% 200%;
            background-position: top center;
            transition: .45s ease all;
            @include flex-element;
            @include safari-overflow;
            transform: translateZ(0) translate3d(0, 0, 0);
            &::before {
                @include pseudo-element;
                left: 50%;
                top: 50%;
                width: 100%;
                padding-top: 100%;
                transform: translateX(-50%) translateY(55%) rotate(45deg) scale(0.5) translateZ(0);
                background: linear-gradient(135deg, $ebony 0%, lighten($ebony, 4%) 100%);
                transition: .75s ease all;
                backface-visibility: hidden;
                will-change: transform;
            }
            img {
                @include image-fit(contain);
                max-width: 50%;
                max-height: 35%;
                transition: .45s ease all;
                position: relative;
                z-index: 1;
                transform: perspective(1000px) translateY(0%) rotateX(0deg) translateZ(0);
            }
            &:hover {
                background-position: bottom center;
                border-image-source: linear-gradient(0deg, $big-stone 0%, rgba($big-stone, 0) 100%);
                &::before {
                    transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1.25) translateZ(0);
                }
                img {
                    transform: perspective(1000px) translateY(-15%) rotateX(15deg) translateZ(15px);
                    filter: drop-shadow(0 10px 5px rgba($black-pearl, 0.75));
                }
            }
        }
    }
}