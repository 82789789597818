.main-menu {
    width: 100%;
    padding-left: 75px;
    pointer-events: none;
    max-width: 175px;
    & > li {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
        transition: .3s ease all;
        & > a {
            display: block;
            position: relative;
            width: fit-content;
            padding: 12.5px 0;
            white-space: nowrap;
            span {
                text-transform: uppercase;
                display: block;
                font-weight: 500;
                font-size: 0.0625rem * 15;
                color: $bali-hai;
                width: fit-content;
                letter-spacing: calc(14px * 5 / 100);
                line-height: 1.5;
                &.actual {
                    color: white;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    transform: translateX(-50%) translateY(-50%);
                    .split-chars {
                        opacity: 0;
                        transform: perspective(1000px) rotateY(60deg);
                        will-change: transform, opacity;
                        backface-visibility: hidden;
                    }
                }
                &.placeholder {
                    color: $bali-hai;
                }
            }
        }
        &.has-child {
            & > .sub-menu {
                display: none;
                max-height: 165px;
                overflow-x: hidden;
                overflow-y: auto;
                min-width: 150px;
                & > li {
                    & > a {
                        display: block;
                        position: relative;
                        width: fit-content;
                        padding: 5px 0;
                        span {
                            min-width: 150px;
                            display: block;
                            font-weight: 500;
                            font-size: 0.0625rem * 15;
                            color: $bali-hai;
                            width: fit-content;
                            line-height: 1.5;
                            transition: .3s ease all;
                            .split-words {
                                white-space: nowrap;
                            }
                            &.actual {
                                color: white;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                width: 100%;
                                transform: translateX(-50%) translateY(-50%);
                                .split-chars {
                                    opacity: 0;
                                    transform: perspective(1000px) rotateY(60deg);
                                    will-change: transform, opacity;
                                    backface-visibility: hidden;
                                }
                            }
                            &.placeholder {
                                color: rgba(white, 0.75);
                            }
                        }
                    }
                }
            }
        }
        &.active {
            & > a {
                span {
                    &.placeholder {
                        opacity: 0;
                    }
                    &.actual {
                        .split-chars { 
                            opacity: 1!important; 
                            transform: perspective(1000px) rotateY(0deg)!important; 
                        }
                    }
                }
            }
        }
    }
}