.form {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: auto;
    grid-gap: $formSpace;
    .item {
        position: relative;
        input:not([type="checkbox"]), textarea, select {
            @include reset-input;
            width: 100%;
            height: $formHeight;
            border: 0;
            margin: 0;
            padding: $formPadding;
            font-size: calc($font-size + 2) * 0.0625rem;
            color: $formColor;
            background-color: $formBackgroundColor;
            border-bottom: 1px solid $formBorderColor;
            border-radius: $formBorderRadius;
            transition: .3s ease all;
            &.error {
                border-color: red;
            }
            &::placeholder {
                color: $formPlaceholderColor;
            }
            &:hover {
                border-bottom: 1px solid $formHoverBorderColor;
            }
            &:focus {
                border-bottom: 1px solid $formFocusBorderColor;
            }
        }
        textarea {
            padding: $formTextareaPadding;
            height: $formTextareaHeight;
            border-radius: $formTextareaBorderRadius;
        }
        &.size {
            &--1 { grid-column: span 1; }
            &--2 { grid-column: span 2; }
            &--3 { grid-column: span 3; }
            &--4 { grid-column: span 4; }
        }
        &.justify {
            &--start { justify-self: start; }
            &--center { justify-self: center; }
            &--end { justify-self: end; }
        }
        &.align {
            &--start { align-self: start; }
            &--center { align-self: center; }
            &--end { align-self: end; }
        }
    }
}

.fancybox__content :focus:not(.carousel__button.is-close) {
    box-shadow: inset 0 0 0 1px $formFocusBorderColor;
}

.custom-acceptance-input {
    display: flex;
    align-items: center;
    .box {
        position: relative;
        cursor: pointer;
        flex-shrink: 0;
        margin-right: 15px;
        input {
            @include absolute-element;
            z-index: 1;
            cursor: pointer;
            padding: 0;
            margin: 0;
            &.error {
                & + .checkbox {
                    box-shadow: inset 0 0 0 1px red;
                }
            }
            &:hover {
                & + .checkbox {
                    box-shadow: inset 0 0 0 1px $formHoverBorderColor;
                }
            }
            &:checked {
                & + .checkbox {
                    box-shadow: inset 0 0 0 1px $formFocusBorderColor;
                    &::after {
                        opacity: 1;
                        transform: translateX(-50%) translateY(-50%) scale(1);
                    }
                }
            }
        }
        .checkbox {
            background-color: transparent;
            backface-visibility: hidden;
            cursor: pointer;
            position: relative;
            transition: .3s ease all;
            width: $formCheckboxWidth;
            height: $formCheckboxHeight;
            box-shadow: inset 0 0 0 1px $formBorderColor;
            border-radius: $formCheckboxBorderRadius;
            background-color: $formBackgroundColor;
            transition: .3s ease all;
            &::after {
                content: '';
                border-radius: $formCheckboxInnerBorderRadius;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%) scale(0.8);
                width: calc($formCheckboxHeight / 2.5);
                height: calc($formCheckboxHeight / 2.5);
                background-color: $formFocusBorderColor;
                opacity: 0;
                transition: .3s ease all;
            }
        }
    }
    .text {
        color: $formTextColor;
        a {
            color: $formLinkColor;
            transition: .3s ease all;
            font-weight: 600;
            &:hover {
                color: $formLinkHoverColor;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .form {
        grid-template-columns: minmax(0, 1fr)!important;
    }
    .form .item.size--1, .form .item.size--2, .form .item.size--3, .form .item.size--4 {
        grid-column: unset!important;
    }
}