.display-block { display: block; }

.align-items-center { align-items: center; }
.align-items-flex-start { align-items: flex-start; }
.align-items-flex-end { align-items: flex-end; }
.align-items-start { align-items: start; }
.align-items-end { align-items: end; }
.align-items-stretch { align-items: stretch; }

.align-content-center { align-content: center; }
.align-content-flex-start { align-content: flex-start; }
.align-content-flex-end { align-content: flex-end; }
.align-content-start { align-content: start; }
.align-content-end { align-content: end; }
.align-content-space-around { align-content: space-around; }
.align-content-space-between { align-content: space-between; }
.align-content-space-evenly { align-content: space-evenly; }
.align-content-stretch { align-content: stretch; }

.align-self-center { align-self: center; }
.align-self-flex-start { align-self: flex-start; }
.align-self-flex-en { align-self: flex-end; }
.align-self-start { align-self: start; }
.align-self-end { align-self: end; }

.justify-items-center { justify-items: center; }
.justify-items-flex-start { justify-items: flex-start; }
.justify-items-flex-end { justify-items: flex-end; }
.justify-items-start { justify-items: start; }
.justify-items-end { justify-items: end; }
.justify-items-stretch { justify-items: stretch; }

.justify-content-center { justify-content: center; }
.justify-content-flex-start { justify-content: flex-start; }
.justify-content-flex-end { justify-content: flex-end; }
.justify-content-start { justify-content: start; }
.justify-content-end { justify-content: end; }
.justify-content-space-around { justify-content: space-around; }
.justify-content-space-between { justify-content: space-between; }
.justify-content-space-evenly { justify-content: space-evenly; }
.justify-content-stretch { justify-content: stretch; }

.justify-self-center { justify-self: center; }
.justify-self-flex-start { justify-self: flex-start; }
.justify-self-flex-en { justify-self: flex-end; }
.justify-self-start { justify-self: start; }
.justify-self-end { justify-self: end; }