*, *:before, *:after {
    box-sizing: border-box;
    outline: unset!important;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

body {
    font-size: $font-size * 1px;
    line-height: $line-height;
    font-family: $font-family, sans-serif;
    user-select: none;
    background-color: $body-background-color;
}

*, *:focus, *:hover, *:active, *::before, *::after {
    outline: unset!important;
}

input, form, table, select, textarea, button {
    font-family: $font-family, sans-serif;
    font-size: $font-size * 1px;
    outline: unset;
    @include reset-input;
}

footer, header, nav, section, main {
    display: block;
}

a, a:hover, a:active, a:focus, a:visited {
    text-decoration: none;
}

ol, ul, menu {
    list-style: none!important;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

svg {
    overflow: visible;
    display: block;
}