.container {
    max-width: $default-container; // Theme Default Size
    width: 100%;
    padding: 0 30px;
    margin: 0 auto;
    &--full-without-space { margin: unset; padding: unset; max-width: 100%; }
    &--full { margin: unset; max-width: 100%; }
    &--2560 { max-width: 2560px; }
    &--1920 { max-width: 1920px; }
    &--1680 { max-width: 1680px; }
    &--1440 { max-width: 1440px; }
    &--1366 { max-width: 1366px; }
    &--1200 { max-width: 1200px; }
    &--1024 { max-width: 1024px; }
    &--900 { max-width: 900px; }
    &--768 { max-width: 768px; }
    &--600 { max-width: 600px; }
    &--480 { max-width: 480px; }
}