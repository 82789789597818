@media only screen and (max-width: 480px) {
    .contact-field .info-field .info-contact { grid-template-columns: minmax(0, 1fr); }
    .service-prolog .image { transform: translateX(25%) translateY(-50%) rotate(45deg); }
    .services-carousel-wrapper .container .services-text-carousel .item .title { font-size: 26px; }
    .about-prolog .content .wrapper .title { font-size: 26px; }
    .about-whatwedo .container .detail .context .list .item { margin: 0 auto; font-size: 14px; }
    .reference-field .reference-list .item { height: 120px !important; }
    .reference-field .reference-list { grid-gap: 20px; }
    .reference-field .headline .sub-title { font-size: 30px; }
    .reference-field .headline .title { font-size: 14px; }
    .about-whatwedo .container .text { text-align: center; }
    .about-whatwedo .container .text .title { font-size: 24px; }
    .product-prolog .content .title { font-size: 24px; }
    .product-prolog { height: unset!important; }
    .product-prolog .content { padding-top: 60px!important; padding-bottom: 120px!important; }
    .product-features .list { font-size: 16px; }
    .product-prolog .background .image:nth-child(2), .product-prolog .background .image:nth-child(3) { opacity: 0.25; }
    .hero .content .image-area .hero-image-carousel .swiper-wrapper .swiper-slide .image { height: 50%; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .title { font-size: 26px; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .expo { font-size: 16px; }
    .index-about .container .text .title { font-size: 24px; }
    .index-about .container .image { height: 50%; opacity: 0.25; }
    .index-products .container .wrapper { margin: 0 auto; flex-shrink: 0; }
    .index-products .container { display: flex; justify-content: center; }
    .index-products .container .wrapper .content .context .heading { max-width: 60%; }
}