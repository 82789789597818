.header-space { padding-top: var(--header-height); }
.sticky { position: sticky!important; top: 45px; transition: .45s ease all!important; }
.split-lines, .split-words, .split-chars { will-change: transform; backface-visibility: hidden; }
.height-dynamic-viewport { height: 100vh; }
.height-dynamic-viewport-offset-header { height: calc(100vh - var(--header-height)); }

.fancybox__thumbs .carousel__slide .fancybox__thumb::after { border-color: $mariner!important; }

.text-hover-effect {
    display: block;
    position: relative;
    width: fit-content;
    span {
        display: block;
        width: fit-content;
        .split-chars {
            will-change: transform, opacity;
            backface-visibility: hidden;
        }
        .split-words {
            white-space: nowrap;
        }
        &.actual {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            transform: translateX(-50%) translateY(-50%);
            .split-chars {
                opacity: 0;
                transform: perspective(1000px) rotateY(60deg) translateZ(0) translate3d(0, 0, 0);
            }
        }
    }
}

.text-reveal-animation {
    display: block;
    position: relative;
    width: fit-content;
    span {
        display: block;
        width: fit-content;
        .split-words {
            white-space: nowrap;
        }
        .split-chars {
            will-change: transform, opacity;
            backface-visibility: hidden;
        }
        &.placeholder {
            .split-chars {
                opacity: 0.1;
            }
        }
        &.actual {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            transform: translateX(-50%) translateY(-50%);
            .split-chars {
                opacity: 0;
                transform: rotateY(60deg) translateZ(0) translate3d(0, 0, 0);
            }
        }
    }
}