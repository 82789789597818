
// Font Size | [USAGE = font-size--14]
@for $i from 10 through 90 { @if $i % 2 == 0 { .font-size--#{$i} { font-size: $i * 0.0625rem; } } }
@media only screen and (max-width: 1440px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 16 { .font-size--#{$i} { font-size: ($i - 2) * 0.0625rem; } } } } }
@media only screen and (max-width: 1366px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 18 { .font-size--#{$i} { font-size: ($i - 4) * 0.0625rem; } } } } }
@media only screen and (max-width: 1200px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 20 { .font-size--#{$i} { font-size: ($i - 6) * 0.0625rem; } } } } }
@media only screen and (max-width: 1024px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 24 { .font-size--#{$i} { font-size: ($i - 8) * 0.0625rem; } } } } }
@media only screen and (max-width: 900px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 28 { .font-size--#{$i} { font-size: ($i - 10) * 0.0625rem; } } } } }
@media only screen and (max-width: 768px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 32 { .font-size--#{$i} { font-size: ($i - 12) * 0.0625rem; } } } } }
@media only screen and (max-width: 600px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 34 { .font-size--#{$i} { font-size: ($i - 14) * 0.0625rem; } } } } }
@media only screen and (max-width: 480px) { @for $i from 10 through 90 { @if $i % 2 == 0 { @if $i > 30 { .font-size--#{$i} { font-size: ($i - 16) * 0.0625rem; } } } } }

// Line Clamp | [USAGE = line-clamp--14]
.line-clamp { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; @for $i from 1 through 10 { &--#{$i} { -webkit-line-clamp: $i; } } }

// Font Weight | [USAGE = font-weight--14]
@for $i from 100 through 900 { @if $i % 100 == 0 { .font-weight--#{$i} { font-weight: $i; } } }

// Text Alignment | [USAGE = text-align--right]
.text-align { &--left { text-align: left; } &--center { text-align: center; } &--right { text-align: right; } }