.quote-field {
    position: relative;
    .texture {
        @include safari-overflow;
        position: absolute;
        bottom: 0;
        left: var(--header-width);
        width: calc(100% - var(--header-width));
        height: 100%;
        max-height: 600px;
        opacity: 0.3;
        z-index: -1;
        pointer-events: none;
        mask-image: linear-gradient(0deg, black 0%, transparent 75%);
        canvas {
            @include absolute-element;
            transform: scale(1.25) translateY(-15%);
        }
        img {
            opacity: 0;
            @include image-fit(cover, center 100%);
        }
    }
    .container {
        .quote {
            margin: 0 auto;
            text-align: center;
            max-width: 675px;
            color: white;
            font-style: italic;
            line-height: 1.25;
        }
        .naming {
            width: fit-content;
            margin: 0 auto;
            text-align: center;
            color: $bali-hai;
            & > * {
                margin: 0 auto;
            }
            .position {
                letter-spacing: calc(14px * 15 / 100);
            }
        }
        .icon-wrapper {
            margin: 0 auto;
            max-width: 45px;
            svg {
                fill: $christine;
                width: 100%;
            }
        }
    }
}