// Margin | [USAGE = margin--20]
@for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; .margin--#{$i} { margin: $value!important; } .margin-top--#{$i} { margin-top: $value!important; } .margin-bottom--#{$i} { margin-bottom: $value!important; } } }
@media only screen and (max-width: 1680px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 180 { .margin--#{$i} { margin: 180px!important; } .margin-top--#{$i} { margin-top: 180px!important; } .margin-bottom--#{$i} { margin-bottom: 180px!important; } } } } }
@media only screen and (max-width: 1440px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 150 { .margin--#{$i} { margin: 150px!important; } .margin-top--#{$i} { margin-top: 150px!important; } .margin-bottom--#{$i} { margin-bottom: 150px!important; } } } } }
@media only screen and (max-width: 1366px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 120 { .margin--#{$i} { margin: 120px!important; } .margin-top--#{$i} { margin-top: 120px!important; } .margin-bottom--#{$i} { margin-bottom: 120px!important; } } } } }
@media only screen and (max-width: 1200px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 90 { .margin--#{$i} { margin: 90px!important; } .margin-top--#{$i} { margin-top: 90px!important; } .margin-bottom--#{$i} { margin-bottom: 90px!important; } } } } }
@media only screen and (max-width: 1024px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 60 { .margin--#{$i} { margin: 60px!important; } .margin-top--#{$i} { margin-top: 60px!important; } .margin-bottom--#{$i} { margin-bottom: 60px!important; } } } } }
@media only screen and (max-width: 768px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 45 { .margin--#{$i} { margin: 45px!important; } .margin-top--#{$i} { margin-top: 45px!important; } .margin-bottom--#{$i} { margin-bottom: 45px!important; } } } } }
@media only screen and (max-width: 425px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 30 { .margin--#{$i} { margin: 30px!important; } .margin-top--#{$i} { margin-top: 30px!important; } .margin-bottom--#{$i} { margin-bottom: 30px!important; } } } } }

// Padding | [USAGE = padding--20]
@for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; .padding--#{$i} { padding: $value!important; } .padding-top--#{$i} { padding-top: $value!important; } .padding-bottom--#{$i} { padding-bottom: $value!important; } } }
@media only screen and (max-width: 1680px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 180 { .padding--#{$i} { padding: 180px!important; } .padding-top--#{$i} { padding-top: 180px!important; } .padding-bottom--#{$i} { padding-bottom: 180px!important; } } } } }
@media only screen and (max-width: 1440px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 150 { .padding--#{$i} { padding: 150px!important; } .padding-top--#{$i} { padding-top: 150px!important; } .padding-bottom--#{$i} { padding-bottom: 150px!important; } } } } }
@media only screen and (max-width: 1366px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 120 { .padding--#{$i} { padding: 120px!important; } .padding-top--#{$i} { padding-top: 120px!important; } .padding-bottom--#{$i} { padding-bottom: 120px!important; } } } } }
@media only screen and (max-width: 1200px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 90 { .padding--#{$i} { padding: 90px!important; } .padding-top--#{$i} { padding-top: 90px!important; } .padding-bottom--#{$i} { padding-bottom: 90px!important; } } } } }
@media only screen and (max-width: 1024px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 60 { .padding--#{$i} { padding: 60px!important; } .padding-top--#{$i} { padding-top: 60px!important; } .padding-bottom--#{$i} { padding-bottom: 60px!important; } } } } }
@media only screen and (max-width: 768px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 45 { .padding--#{$i} { padding: 45px!important; } .padding-top--#{$i} { padding-top: 45px!important; } .padding-bottom--#{$i} { padding-bottom: 45px!important; }} } } }
@media only screen and (max-width: 425px) { @for $i from 0 through 250 { @if $i % 5 == 0 { $value: $i * 1px; @if $i > 30 { .padding--#{$i} { padding: 30px!important; } .padding-top--#{$i} { padding-top: 30px!important; } .padding-bottom--#{$i} { padding-bottom: 30px!important; }} } } }