@media only screen and (max-width: 1366px) {
    .service-prolog .content > .wrapper .sub-title { font-size: 32px; }
    .service-prolog .content > .wrapper .expo { font-size: 20px; }
    .scroll-down { width: 90px; height: 90px; }
    .scroll-down > span { font-size: 10px; }
    .service-process .wrapper .content .text { font-size: 20px; }
    .service-process .wrapper .content { max-width: 60%; }
    .service-process .wrapper .corners .item { width: 60px; height: 60px; font-size: 10px; }
    .button-style--default-border { padding: 0 20px; height: 50px; }
    .button-style--icon-edge { height: 50px; padding: 0 50px 0 20px; }
    .button-style--icon-edge-color { height: 50px; padding: 0 50px 0 20px; }
    .button-style--default { height: 50px; padding: 0 20px; }
    .button-style--icon-edge:hover > .icon::before { transform: translateY(-50%) scaleY(1.2) rotate(0deg); }
    .button-style--icon-edge-color:hover > .icon::before { transform: translateY(-50%) scaleY(1.2) rotate(0deg); }
    .services-carousel-wrapper .container .services-text-carousel .item .title { font-size: 40px; }
    .service-detail .container .detail .title { font-size: 36px; }
    .service-detail .container .owner .text { font-size: 24px; }
    .service-detail .container .detail .list .item { font-size: 20px; }
    .contact-field .info-field .headline { font-size: 34px; }
    .contact-field .info-field .info-contact { margin-bottom: 30px!important; }
    .contact-field .info-field .info-working-hours { margin-bottom: 30px!important; }
    .contact-field .info-field .info-address { margin-bottom: 45px!important; }
    .contact-field .info-field .info-contact { grid-gap: 45px; }
    .about-prolog .content .wrapper .title { font-size: 36px; }
    .quote-field .container .quote { font-size: 40px; }
    .about-whatwedo .container .text .title { font-size: 44px; }
    .about-whatwedo .container .detail .context .title { font-size: 38px; }
    .about-whatwedo .container .detail .context .list .item { font-size: 18px; }
    .reference-field .headline .sub-title { font-size: 44px; }
    .product-prolog .content .title { font-size: 38px; }
    .product-features .list { font-size: 24px; }
    .product-features .list .split { margin: 30px 0; }
    .hero .scroll-down { left: 76.5%; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .title { margin-bottom: 20px!important; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .button-wrapper { margin-top: 20px!important; }
    .index-about .container .text .title { font-size: 40px; }
    .index-about .container .text .expo { font-size: 20px; }
}