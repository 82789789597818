header {
    z-index: 100;
    position: fixed;
    width: var(--header-width);
    height: var(--header-height);
    left: 0;
    top: 0;
    transition: .45s ease all;
    will-change: height, background-color;
    backface-visibility: hidden;
    background-color: $black-pearl;
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 45px;
        width: 100%;
        background-color: $black-pearl;
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    }
    /* &.no-scroll {
        position: absolute;
        transform: unset!important;
        box-shadow: unset!important;
        height: var(--header-height)!important;
    } */
    &.active {
        width: 220px;
        .wrapper .logo { width: 175px; }
        .wrapper .logo svg .split { transform: scaleY(1); transition-delay: .3s; }
        .wrapper .logo svg .word .letter { opacity: 1; transform: perspective(1000px) rotateX(0deg) scale(1); @for $i from 1 through 9 { &:nth-child(#{$i}) { transition-delay: $i * 0.1s; } } }
        .main-menu {
            pointer-events: visible;
            & > li {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                @for $i from 1 through 10 { &:nth-child(#{$i}) { transition-delay: $i * 0.1s; } }
            }
        }
        .wrapper .language {
            pointer-events: visible;
            .list {
                & > a {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    @for $i from 1 through 10 { &:nth-child(#{$i}) { transition-delay: $i * 0.1s; } }
                }
            }
        }
    }
    &.sub-menu {
        width: 300px;
    }
    .wrapper {
        height: 100%;
        padding: 30px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .logo {
            width: 30px;
            margin: 0 auto;
            transition: .45s ease all;
            flex-shrink: 0;
            @include safari-overflow;
            svg {
                overflow: hidden;
                flex-shrink: 0;
                width: 175px;
                .split { transform: scaleY(0); transition: .45s ease all; transform-origin: 50% 50%; }
                .word .letter { transform-box: fill-box; opacity: 0; transform: perspective(1000px) rotateY(90deg) scale(0.75); transition: .45s ease all; }
            }
        }
        .menu-trigger {
            position: absolute;
            top: 50%;
            left: 27px;
            transform: translateY(-50%);
            width: 21px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            pointer-events: all;
            &::before {
                content: '';
                position: absolute;
                right: 100%;
                height: 100%;
                width: 100%;
                top: 0;
                pointer-events: none;
            }
            &:not(.active) {
                &:hover {
                    span { background-color: $bali-hai; }
                    span:nth-child(2) { height: 120%; }
                }
            }
            &.active {
                &::before { pointer-events: visible; }
                span:nth-child(1) { height: calc(var(--header-height) - 45px - 45px - 30px - 30px); transform: translateY(7.5px); background-color: rgba($bali-hai, 0.1); }
                span:nth-child(2) { transform: translateX(-10px) translateY(3px) rotate(30deg) };
                span:nth-child(3) { transform: translateX(-20px) translateY(3px) rotate(-30deg) };
                &:hover {
                    span:nth-child(2) { background-color: $bali-hai; transform: translateX(-10px) translateY(3px) rotate(40deg) }
                    span:nth-child(3) { background-color: $bali-hai; transform: translateX(-20px) translateY(3px) rotate(-40deg) }
                }
            }
            span { 
                transition: .45s ease all;
                display: block;
                height: 100%;
                width: 1px;
                background-color: rgba($bali-hai, 0.5);
            }
        }
        .language {
            pointer-events: none;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            width: fit-content;
            &:hover {
                .icon {
                    svg {
                        fill: rgba($bali-hai, 0.5);
                        transform: scale(1.1);
                    }
                }
            }
            .icon {
                width: var(--header-width);
                height: 26px;
                svg {
                    will-change: transform, fill;
                    backface-visibility: hidden;
                    transition: .3s ease all;
                    fill: rgba($bali-hai, 0.25);
                }
            }
            .list {
                display: flex;
                align-items: center;
                @include supports(gap, 20px, margin-left, 20px);
                & > a {
                    flex-shrink: 0;
                    display: block;
                    width: fit-content;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateX(10px);
                    transition: .3s ease all;
                    &.active {
                        pointer-events: none;
                        span {
                            &.placeholder {
                                opacity: 0;
                            }
                            &.actual {
                                .split-chars { 
                                    opacity: 1!important; 
                                    transform: perspective(1000px) rotateY(0deg)!important; 
                                }
                            }
                        }
                    }
                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 0.0625rem * 15;
                        &.placeholder {
                            color: rgba($bali-hai, 0.5);
                        }
                        &.actual {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}