@media only screen and (max-width: 768px) {
    .service-prolog .image { height: 50%; }
    .container { padding: 0 20px; }
    header { width: 100%; height: 75px; }
    main { padding-top: 75px; }
    header::after { content: unset; display: none; }
    header.active { width: 100%; }
    header.active .wrapper .logo { width: unset; }
    header .wrapper { padding: 0 20px; flex-direction: row; justify-content: flex-end; @include supports(gap, 30px, margin-left, 30px); }
    header .wrapper .menu-trigger { position: relative; top: unset; left: unset; transform: unset }
    header .wrapper .logo { @include flex-element; margin: unset; flex-shrink: 0; width: unset; position: absolute; left: 20px; top: 50%; transform: translateY(-50%) }
    header .wrapper .logo svg .split { transform: unset; }
    header .wrapper .logo svg .word .letter { transform: unset; opacity: 1; }
    header .wrapper .language .icon { width: 26px; }
    header .wrapper .language { pointer-events: visible; }
    header .wrapper .language .list { margin-left: 15px; @include supports(gap, 10px, margin-left, 10px) }
    header .wrapper .language .list > a { opacity: 1!important; transform: unset!important; visibility: visible!important; }
    header .wrapper .menu-trigger { height: 100%; padding: 20px 0; transform: rotate(90deg); }
    header .wrapper .menu-trigger.active span:nth-child(1) { height: 0; opacity: 0; }
    header .wrapper .menu-trigger.active span:nth-child(2) { transform: translateX(0px) translateY(3px) rotate(30deg); }
    header .wrapper .menu-trigger.active span:nth-child(3) { transform: translateX(-10px) translateY(3px) rotate(-30deg); }
    header .wrapper .menu-trigger.active:hover span:nth-child(2) { transform: translateX(0px) translateY(3px) rotate(30deg); }
    header .wrapper .menu-trigger.active:hover span:nth-child(3) { transform: translateX(-10px) translateY(3px) rotate(-30deg); }
    header.active .main-menu { opacity: 1; pointer-events: visible; }
    header.is-fixed { transform: translateY(0%); }
    header.hidden { transform: translateY(-100%); }
    .main-menu { position: absolute; top: 100%; left: 0; width: 100%; background-color: $black-pearl; max-width: unset; margin: unset; padding: 30px 20px; border-top: 1px solid $firefly; opacity: 0; pointer-events: none; }

    .services-carousel-wrapper .container .services-image-carousel .swiper-wrapper .swiper-slide .image { height: 50%; }
    .services-carousel-wrapper .container .services-image-carousel .carousel-nav .prev, .services-carousel-wrapper .container .services-image-carousel .carousel-nav .next { width: 30px; height: 30px; }
    .services-carousel-wrapper .container .services-image-carousel .carousel-nav .prev svg, .services-carousel-wrapper .container .services-image-carousel .carousel-nav .next svg { height: 12px; }
    .services-carousel-wrapper .container .services-image-carousel .carousel-nav { top: 78%; }
    .service-prolog .content > .wrapper { justify-content: center; padding-top: 0!important; padding-bottom: 0!important; }
    .quote-field .texture { left: unset; width: 100%; }
    .product-prolog .content .title { font-size: 28px; }
    .product-features .list { font-size: 18px; }
    .product-features .texture { left: unset; width: 100%; }
    .contact-field .texture { left: 0; width: 100%; }
    .index-products { @include safari-overflow; padding-top: 120px; }
    .services-carousel-wrapper .container .services-text-carousel .item .button { margin: 30px auto 0 0!important; }
}