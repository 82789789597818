@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-Light.eot');
    src: local('Volte Light'), local('Volte-Light'),
        url('../font/volte/Volte-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-Light.woff2') format('woff2'),
        url('../font/volte/Volte-Light.woff') format('woff'),
        url('../font/volte/Volte-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-BoldItalic.eot');
    src: local('Volte Bold Italic'), local('Volte-BoldItalic'),
        url('../font/volte/Volte-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-BoldItalic.woff2') format('woff2'),
        url('../font/volte/Volte-BoldItalic.woff') format('woff'),
        url('../font/volte/Volte-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-LightItalic.eot');
    src: local('Volte Light Italic'), local('Volte-LightItalic'),
        url('../font/volte/Volte-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-LightItalic.woff2') format('woff2'),
        url('../font/volte/Volte-LightItalic.woff') format('woff'),
        url('../font/volte/Volte-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-SemiboldItalic.eot');
    src: local('Volte Semibold Italic'), local('Volte-SemiboldItalic'),
        url('../font/volte/Volte-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-SemiboldItalic.woff2') format('woff2'),
        url('../font/volte/Volte-SemiboldItalic.woff') format('woff'),
        url('../font/volte/Volte-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-Bold.eot');
    src: local('Volte Bold'), local('Volte-Bold'),
        url('../font/volte/Volte-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-Bold.woff2') format('woff2'),
        url('../font/volte/Volte-Bold.woff') format('woff'),
        url('../font/volte/Volte-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-Medium.eot');
    src: local('Volte Medium'), local('Volte-Medium'),
        url('../font/volte/Volte-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-Medium.woff2') format('woff2'),
        url('../font/volte/Volte-Medium.woff') format('woff'),
        url('../font/volte/Volte-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-MediumItalic.eot');
    src: local('Volte Medium Italic'), local('Volte-MediumItalic'),
        url('../font/volte/Volte-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-MediumItalic.woff2') format('woff2'),
        url('../font/volte/Volte-MediumItalic.woff') format('woff'),
        url('../font/volte/Volte-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-Regular.eot');
    src: local('Volte'), local('Volte-Regular'),
        url('../font/volte/Volte-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-Regular.woff2') format('woff2'),
        url('../font/volte/Volte-Regular.woff') format('woff'),
        url('../font/volte/Volte-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-RegularItalic.eot');
    src: local('Volte Regular Italic'), local('Volte-RegularItalic'),
        url('../font/volte/Volte-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-RegularItalic.woff2') format('woff2'),
        url('../font/volte/Volte-RegularItalic.woff') format('woff'),
        url('../font/volte/Volte-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    ascent-override: 105%;
}

@font-face {
    font-family: 'Volte';
    src: url('../font/volte/Volte-Semibold.eot');
    src: local('Volte Semibold'), local('Volte-Semibold'),
        url('../font/volte/Volte-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../font/volte/Volte-Semibold.woff2') format('woff2'),
        url('../font/volte/Volte-Semibold.woff') format('woff'),
        url('../font/volte/Volte-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    ascent-override: 105%;
}