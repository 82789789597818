/* [------------------] Colors [------------------] */
    
    $orange: #FD6A1A;
    $christine: #E7820D;
    $black-pearl: #020D16;
    $ebony: #0B1620;
    $firefly: #0F1E2B;
    $big-stone: #162C3F;
    $nobel: #B5B2B2;
    $bali-hai: #889EB0;
    $slate-gray: #708895;
    $hippie-blue: #57A0B1;
    $blue-dianne: #25425A;
    $pickled-bluewood: #293B4A;
    $mariner: #1D4EE2;
    
    $main-color: $christine;
    $secondary-color: $mariner;
    $text-color: $bali-hai;
    $border-color: rgba($bali-hai, 0.3);
    $body-background-color: $ebony; 

/* [------------------] Default Values [------------------] */
    :root {
        --header-height: 625px;
        --header-width: 75px;
    }

    $font-family: 'Volte';
    $font-size: 16;
    $line-height: 1.5;
    $default-space: 30px; 
    $default-container: 1440px;

    $buttonBorderRadius: 2px;
    $buttonHeight: 75px;

    $formSpace: 45px 30px;
    $formColor: white;
    $formPlaceholderColor: $bali-hai;
    $formBackgroundColor: transparent;
    $formBorderColor: $border-color;
    $formHoverBorderColor: lighten($border-color, 10%);
    $formFocusBorderColor: $hippie-blue;
    $formHeight: auto;
    $formTextareaHeight: 90px;
    $formTextareaPadding: 0px 10px;
    $formBorderRadius: 0px;
    $formTextareaBorderRadius: 0px;
    $formPadding: 0px 10px 30px 10px;
    $formCheckboxHeight: 18px;
    $formCheckboxWidth: 18px;
    $formCheckboxBorderRadius: 0px;
    $formCheckboxInnerBorderRadius: 0px;
    $formTextColor: $bali-hai;
    $formLinkColor: $bali-hai;
    $formLinkHoverColor: $hippie-blue;

/* [------------------] Filters [------------------] */

    $filter-mariner: invert(24%) sepia(94%) saturate(4653%) hue-rotate(227deg) brightness(91%) contrast(94%);
    $filter-orange: invert(62%) sepia(86%) saturate(4319%) hue-rotate(349deg) brightness(101%) contrast(98%);
    $filter-christine: invert(56%) sepia(47%) saturate(998%) hue-rotate(353deg) brightness(91%) contrast(98%);
    $filter-bali-hai: invert(66%) sepia(22%) saturate(305%) hue-rotate(165deg) brightness(89%) contrast(93%);