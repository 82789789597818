.princibles-field {
    &.centered {
        .item {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        position: relative;
        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: rgba($bali-hai, 0.12);
        }
        &::before { left: 34%; }
        &::after { left: 66%; }
        .item {
            max-width: 250px;
            margin: 0 auto;
            .icon { 
                width: 40px;
                height: 40px;
                fill: $christine;
            }
            .title {
                color: white;
                letter-spacing: calc(24px * 10 / 100);
            }
            .expo {
                color: $bali-hai;
            }
        }
    }
}