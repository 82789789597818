.product-prolog {
    height: 100vh;
    position: relative;
    .background {
        @include absolute-element;
        @include safari-overflow;
        background-color: $ebony;
        pointer-events: none;
        .image {
            @include safari-overflow;
            position: absolute;
            mix-blend-mode: lighten;
            backface-visibility: hidden;
            will-change: transform, opacity;
            &::after {
                @include pseudo-element;
                background: radial-gradient(circle at 50% 50%, rgba($ebony, 0) 0%, rgba($ebony, 0.75) 100%);
                mix-blend-mode: multiply;
            }
            img {
                @include absolute-element;
                @include image-fit;
                transform: scale(1.5) rotate(-45deg) translateZ(0) translate3d(0, 0, 0);
            }
            &:nth-child(1) {
                height: 66%;
                top: 50%;
                left: 50%;
                opacity: 0.2;
                transform: translateX(-50%) translateY(-50%) rotate(45deg) translateZ(0) translate3d(0, 0, 0);
            }
            &:nth-child(2) { 
                height: 40%;
                top: 15%;
                left: 77%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg) translateZ(0) translate3d(0, 0, 0);
            }
            &:nth-child(3) { 
                height: 40%;
                top: 70%;
                left: 85%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg) translateZ(0) translate3d(0, 0, 0);
            }
        }
    }
    .content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 1;
        .title {
            color: white;
            line-height: 1.25;
            max-width: 1000px;
        }
        .expo {
            max-width: 600px;
            color: $bali-hai;
        }
    }
    .gallery-trigger {
        z-index: 2;
        position: absolute;
        left: 66%;
        top: 87%;
        transform: translateX(-50%) translateY(-50%);
        width: 90px;
        height: 90px;
        img { display: none; }
        .wrapper {
            @include absolute-element;
            @include flex-element;
            transforM: rotate(45deg);
            &::after {
                @include pseudo-element;
                transform: scale(0.8);
                background-color: #11212F;
                z-index: 2;
                box-shadow: 0 0 0 10px $ebony;
                transition: .45s ease all;
            }
            &::before {
                @include pseudo-element;
                background: linear-gradient(90deg, $mariner 0%, rgba(#22384A, 0) 50%, rgba(#22384A, 0) 50%, $mariner 100%);
                background-size: 200% 100%;
                background-position: left center;
                z-index: 1;
                transition: .45s ease all;
            }
            svg {
                width: 26px;
                height: 26px;
                fill: $mariner;
                transform: rotate(-45deg);
                position: relative;
                z-index: 3;
                transition: .3s ease fill;
            }
        }
        .text {
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%) translateX(30px); 
            letter-spacing: calc(16px * 12 / 100);
            color: white;
            .actual {
                color: $mariner;
            }
        }
        &:hover {
            .wrapper::before { background-position: right center; }
            .wrapper::after { background-color: $mariner; }
            svg { filL: white; }
        }
    }
}

.product-features {
    position: relative;
    .texture {
        @include safari-overflow;
        position: absolute;
        bottom: 0;
        left: var(--header-width);
        width: calc(100% - var(--header-width));
        height: 100%;
        max-height: 600px;
        opacity: 0.3;
        z-index: -1;
        pointer-events: none;
        mask-image: linear-gradient(0deg, black 0%, transparent 75%);
        canvas {
            @include absolute-element;
            transform: scale(1.25) translateY(-15%) translateZ(0) translate3d(0, 0, 0);
        }
        img {
            opacity: 0;
            @include image-fit(cover, center 100%);
        }
    }
    .heading {
        color: $bali-hai;
        letter-spacing: calc(18px * 22 / 100);
        text-align: center;
        margin: 0 auto;
        width: fit-content;
    }
    .list {
        max-width: 850px;
        margin: 0 auto;
        .item {
            position: relative;
            text-align: center;
            display: block;
            width: fit-content;
            color: white;
            margin: 0 auto;
        }
        .split {
            width: 100%;
            height: 1px;
            margin: 40px 0;
            background-color: rgba($bali-hai, 0.12);
        }
    }
}