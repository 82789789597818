.services-carousel-wrapper {
    @include safari-overflow;
    .container {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 180px;
        align-items: center;
        .services-text-carousel {
            width: 100%;
            overflow: visible;
            .item {
                opacity: 0;
                visibility: hidden;
                transition: .6s ease all;
                pointer-events: none;
                transform: scale(0.75);
                will-change: transform, opacity;
                .sub-title {
                    color: $bali-hai;
                    letter-spacing: calc(18px * 22 / 100);
                }
                .title {
                    color: white;
                    line-height: 1.1;
                    position: relative;
                    display: block;
                }
                .expo {
                    color: $bali-hai;
                    display: block;
                }
            }
            .swiper-slide-active {
                .item {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: visible;
                    transform: scale(1);
                }
            }
        }
        .services-image-carousel {
            width: 100%;
            height: 100%;
            overflow: visible;
            position: relative;
            .carousel-nav {
                position: absolute;
                z-index: 5;
                left: 80%;
                top: 78%;
                .count {
                    position: absolute;
                    right: 100%;
                    top: 50%;
                    transform: translateY(50%);
                    display: flex;
                    align-items: center;
                    .current {
                        color: white;
                        font-weight: 600;
                        margin-right: 5px;
                    }
                    .total {
                        color: $bali-hai;
                        &::before {
                            content: '/';
                            color: $bali-hai;
                        }
                    }
                }
                .prev, .next {
                    width: 60px;
                    height: 60px;
                    box-shadow: 0 0 0 1px rgba($hippie-blue, 0.35);
                    transform: rotate(45deg);
                    @include flex-element;
                    transition: .45s ease all;
                    cursor: pointer;
                    &.swiper-button-disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                    &:hover {
                        background-color: $hippie-blue;
                        svg {
                            fill: white;
                        }
                    }
                    svg {
                        transform: rotate(-45deg);
                        fill: $hippie-blue;
                        height: 20px;
                        transition: .3s ease all;
                    }
                }
                .next {
                    transform: translateX(95%) rotate(45deg);
                    svg { transform: rotate(-45deg) translateX(2px) }
                    &:hover {
                        svg { transform: rotate(-45deg) translateX(5px) }
                    }
                }
                .prev {
                    svg { transform: rotate(-45deg) translateX(-2px) }
                    &:hover {
                        svg { transform: rotate(-45deg) translateX(-5px) }
                    }
                }
            }
            .swiper-wrapper {
                height: 100%;
                .swiper-slide {
                    @include flex-element;
                    height: 100%;
                    backface-visibility: hidden;
                    overflow: visible;
                    .image {
                        display: block;
                        backface-visibility: hidden;
                        height: 60%;
                        position: relative;
                        .shadow {
                            @include absolute-element;
                            transform: scale(1.5);
                            opacity: 0;
                            transition: .45s ease opacity;
                            pointer-events: none;
                            .circle-wrapper { clip-path: url(#clip-path); }
                            .circle { fill: url(#circle-gradient); }
                            .line { fill: none; stroke: none; stroke-miterlimit: 10; stroke-width: 2px; }
                            .clip-path-content { fill: none; }
                        }
                        .wrapper {
                            @include absolute-element;
                            transform: rotate(45deg);
                            mix-blend-mode: lighten;
                            background-color: $ebony;
                            @include safari-overflow;
                            &::after {
                                @include pseudo-element;
                                background: radial-gradient(circle at 50% 50%, rgba($ebony, 0) 0%, rgba($ebony, 0.75) 100%);
                                mix-blend-mode: multiply;
                            }
                            img {
                                transform: scale(1.5) rotate(-45deg);
                                @include image-fit;
                            }
                        }
                    }
                    &.swiper-slide-active {
                        .image .shadow { opacity: 1; }
                    }
                }
            }
        }
    }
}