.carousel__button.is-close {
    svg {
        transition: .3s ease all;
    }
    &:hover {
        svg {
            transform: rotate(-90deg);
        }
    }
}

.kvkk-popup {
    width: 100%;
    height: 100%;
    max-width: 550px;
    max-height: 90%;
    padding: 45px 0;
    margin: 0;
    background: $black-pearl;
    border-radius: 2px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 45px;
        width: 100%;
        background-color: $black-pearl;
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    }
    .wrapper {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 45px;
        .inner {
            width: 100%;
            height: 100%;
            text-align: justify;
        }
    }
}