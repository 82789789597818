.no-content {
    .wrapper {
        border: 1px solid rgba($border-color, 0.5);
        display: flex;
        align-items: center;
        padding: 30px;
        span {
            color: $text-color;
            font-weight: 500;
        }
        svg {
            width: 24px;
            height: 24px;
            fill: $main-color;
            flex-shrink: 0;
            margin-right: 30px;
        }
    }
}