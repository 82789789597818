footer {
    background-color: $black-pearl;
    padding: 20px 0;
    .copyright {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text {
            color: $pickled-bluewood;
            font-weight: 500;
            a {
                color: $pickled-bluewood;
                transition: .3s ease all;
                font-weight: bold;
                &:hover {
                    color: $bali-hai;
                }
            }
        }
        .penta-logo {
            filter: invert(22%) sepia(26%) saturate(567%) hue-rotate(165deg) brightness(91%) contrast(98%);
        }
    }
}