@keyframes ripple {
    0% { opacity: 0; transform: scale(1); }
    5% { opacity: 0.5; transform: scale(1); }
    50% { opacity: 0.5; transform: scale(1.5); }
    100% { opacity: 0; transform: scale(2); }
}

@keyframes copied {
    0% { transform: translateX(-50%) translateY(0); opacity: 0 }
    20% { transform: translateX(-50%) translateY(0); opacity: 1; visibility: visible; }
    80% { transform: translateX(-50%) translateY(0); opacity: 1; visibility: visible; }
    100% { transform: translateX(-50%) translateY(-15px); opacity: 0; }
}

@keyframes ring {
    0% { transform: rotate(-15deg); }
    2% { transform: rotate(15deg); }
    4% { transform: rotate(-18deg); }
    6% { transform: rotate(18deg); }
    8% { transform: rotate(-22deg); }
    10% { transform: rotate(22deg); }
    12% { transform: rotate(-18deg); }
    14% { transform: rotate(18deg); }
    16% { transform: rotate(-12deg); }
    18% { transform: rotate(12deg); }
    20% { transform: rotate(0deg); }
}