.editor {
    color: $text-color;
    font-size: $font-size * 0.0625rem;
    line-height: $line-height;

    // Space
    &:not(.no-space) > *:not(:last-child) {
        margin-bottom: $default-space * 0.75;
    }

    &.big-paragraph {
        p { font-size: ($font-size + 2) * 0.0625rem; }
        em { font-size: ($font-size + 4) * 0.0625rem; }
        ul > li, ol > li { font-size: ($font-size + 2) * 0.0625rem; }
    }

    &.bigger-paragraph {
        p { font-size: ($font-size + 4) * 0.0625rem; }
        em { font-size: ($font-size + 8) * 0.0625rem; }
        ul > li, ol > li { font-size: ($font-size + 4) * 0.0625rem; }
    }

    // Heading
    h1, h2, h3, h4 { line-height: calc($line-height * 0.75); color: darken($text-color, 10%); }
    h1 { font-size: ($font-size + 32) * 0.0625rem; }
    h2 { font-size: ($font-size + 26) * 0.0625rem; }
    h3 { font-size: ($font-size + 20) * 0.0625rem; }
    h4 { font-size: ($font-size + 12) * 0.0625rem; }
    h5 { font-size: ($font-size + 10) * 0.0625rem; }
    h6 { font-size: ($font-size + 6) * 0.0625rem; }

    // Text Editing
    strong, b { font-weight: bold; }
    u { text-decoration: none; border-bottom: 1px solid $main-color; }
    i { font-style: italic; }
    p { font-size: $font-size * 0.0625rem; color: $text-color; }
    em { display: block; font-size: ($font-size + 4) * 0.0625rem; color: $text-color; font-weight: 300; }
    a { color: $main-color; transition: .3s ease all; &:hover { color: darken($main-color, 15%); } }
    blockquote {  
        position: relative;
        font-style: italic;
        padding-top: 30px;
        &::before {
            content: '';
            position: absolute;
            @include background-image('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC41NyAxOS40MSI+PHBhdGggZD0iTTguMDEsMy41Yy0zLjA1LC40My00LjQ2LDQuODQtNC4wNiw3Ljc5aDQuMDZ2OC4xMkguMDdDLS4wMiwxMi4zMy0uOTYsMS4wNSw4LjAxLDBWMy41Wm0xMi41NiwwYy0zLjM5LC41Ni00LjUxLDQuODMtNC4wNiw3Ljc5aDQuMDZ2OC4xMmgtNy45N2MtLjAyLTYuNjgtMS4xNC0xOC40NCw3Ljk3LTE5LjQxVjMuNVoiLz48L3N2Zz4=');
            left: -10px;
            top: 0;
            width: 20px;
            height: 20px;
            filter: invert(30%) sepia(100%) saturate(2714%) hue-rotate(218deg) brightness(74%) contrast(114%);
        }
    }
    ul, ol {
        li {
            font-size: $font-size * 0.0625rem;
            line-height: $line-height;
            color: $text-color;
            padding-left: 30px;
            position: relative;
            margin-bottom: calc($default-space / 2);
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                pointer-events: none;
                transition: .3s ease all;
                background-color: $main-color;
                backface-visibility: hidden;
            }
        }
    }
    img {
        max-width: 100%;
    }
    &.alignleft, .alignleft { float: left; margin-right: $default-space; }
    &.alignright, .alignright { float: right; margin-left: $default-space; }
    &.center { text-align: center; }
}

@media only screen and (max-width: 1366px) {
    $font-size: 18;
    $line-height: 1.5;
    $default-space: 20px;
    .editor {
        font-size: $font-size * 0.0625rem;
        line-height: $line-height;
        & > *:not(:last-child) { margin-bottom: $default-space; }
        h1, h2, h3, h4 { line-height: calc($line-height * 0.75); }
        h1 { font-size: ($font-size + 24) * 0.0625rem; }
        h2 { font-size: ($font-size + 20) * 0.0625rem; }
        h3 { font-size: ($font-size + 12) * 0.0625rem; }
        h4 { font-size: ($font-size + 8) * 0.0625rem; }
        h5 { font-size: ($font-size + 4) * 0.0625rem; }
        h6 { font-size: ($font-size + 2) * 0.0625rem; }
        p { font-size: $font-size * 0.0625rem; }
        em { font-size: ($font-size + 4) * 0.0625rem; }
        ul, ol { li { font-size: $font-size * 0.0625rem; margin-bottom: calc($default-space / 2); } }
        &.bigger-paragraph {
            p { font-size: $font-size * 0.0625rem; }
            em { font-size: ($font-size + 4) * 0.0625rem; }
            ul > li, ol > li { font-size: $font-size * 0.0625rem; }
        }
        &.big-paragraph {
            p { font-size: $font-size * 0.0625rem; }
            em { font-size: ($font-size + 4) * 0.0625rem; }
            ul > li, ol > li { font-size: $font-size * 0.0625rem; }
        }
    }
}

@media only screen and (max-width: 1024px) {
    $font-size: 16;
    $line-height: 1.5;
    $default-space: 20px;
    .editor {
        font-size: $font-size * 0.0625rem;
        line-height: $line-height;
        & > *:not(:last-child) { margin-bottom: $default-space; }
        h1, h2, h3, h4 { line-height: calc($line-height * 0.75); }
        h1 { font-size: ($font-size + 20) * 0.0625rem; }
        h2 { font-size: ($font-size + 18) * 0.0625rem; }
        h3 { font-size: ($font-size + 10) * 0.0625rem; }
        h4 { font-size: ($font-size + 8) * 0.0625rem; }
        h5 { font-size: ($font-size + 4) * 0.0625rem; }
        h6 { font-size: ($font-size + 2) * 0.0625rem; }
        p { font-size: $font-size * 0.0625rem; }
        em { font-size: ($font-size + 4) * 0.0625rem; }
        ul, ol { li { font-size: $font-size * 0.0625rem; margin-bottom: calc($default-space / 2); } }
        &.bigger-paragraph {
            p { font-size: $font-size * 0.0625rem; }
            em { font-size: ($font-size + 4) * 0.0625rem; }
            ul > li, ol > li { font-size: $font-size * 0.0625rem; }
        }
        &.big-paragraph {
            p { font-size: $font-size * 0.0625rem; }
            em { font-size: ($font-size + 4) * 0.0625rem; }
            ul > li, ol > li { font-size: $font-size * 0.0625rem; }
        }
    }
}

@media only screen and (max-width: 600px) {
    $font-size: 14;
    $default-space: 15px;
    .editor {
        & > *:not(:last-child) { margin-bottom: $default-space; }
        h1 { font-size: ($font-size + 16) * 0.0625rem; }
        h2 { font-size: ($font-size + 14) * 0.0625rem; }
        h3 { font-size: ($font-size + 8) * 0.0625rem; }
        h4 { font-size: ($font-size + 4) * 0.0625rem; }
        h5 { font-size: ($font-size + 2) * 0.0625rem; }
        h6 { font-size: ($font-size + 2) * 0.0625rem; }
        em { font-size: ($font-size + 4) * 0.0625rem; }
        ul, ol { li { font-size: $font-size * 0.0625rem; margin-bottom: calc($default-space / 1.5); } }
        &.bigger-paragraph {
            p { font-size: ($font-size + 2) * 0.0625rem; }
            em { font-size: ($font-size + 4) * 0.0625rem; }
            ul > li, ol > li { font-size: $font-size * 0.0625rem; }
        }
        &.big-paragraph {
            p { font-size: ($font-size + 2) * 0.0625rem; }
            em { font-size: ($font-size + 4) * 0.0625rem; }
            ul > li, ol > li { font-size: $font-size * 0.0625rem; }
        }
    }
}