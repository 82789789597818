.button {
    cursor: pointer;
    height: $buttonHeight;
    border-radius: $buttonBorderRadius;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    position: relative;
    backface-visibility: hidden;
    padding: 0 20px;
    transition: .33s ease all;
    width: fit-content;
    span, img, svg {
        position: relative;
        z-index: 2;
        backface-visibility: hidden;
        flex-shrink: 0;
        transition: .33s ease all;
    }
    span {
        display: block;
        white-space: nowrap;
        line-height: 1.5;
    }
    svg, img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
    }
    &-icon-size {
        @for $i from 6 through 48 { @if $i % 2 == 0 { $value: $i * 1px; &--#{$i} svg { width: $value!important; height: $value!important; } } }
    }
    &-icon-position {
        @for $i from 5 through 45 { @if $i % 5 == 0 { $value: $i * 1px; &-left--#{$i} svg { margin-right: $value!important; } &-right--#{$i} svg { margin-left: $value!important; } } }
    }
    &-style {
        &--icon-edge {
            height: 60px;
            background-color: $firefly;
            padding: 0 50px 0 20px;
            margin-right: 30px;
            &::before {
                @include pseudo-element;
                width: 42px;
                height: 100%;
                top: 50%;
                right: 0;
                left: unset;
                transform: translateY(-50%);
                background-color: $mariner;
                opacity: 0;
                transition: .6s ease width, opacity .2s ease;
                border-radius: 2px;
            }
            & > .icon {
                position: absolute;
                height: 42px;
                width: 42px;
                right: 0;
                top: 50%;
                @include flex-element;
                transform: translateY(-50%) translateX(50%);
                transition: .45s ease all;
                &::before {
                    @include pseudo-element;
                    border-radius: 2px;
                    top: 50%;
                    background-color: $big-stone;
                    transform: translateY(-50%) rotate(45deg);
                    transition: .45s ease all;
                }
                svg {
                    fill: $bali-hai;
                    transition: .3s ease all;
                }
            }
            span {
                color: $bali-hai;
                letter-spacing: calc(16px * 10 / 100);
            }
            &:hover {
                &::before { width: 100%; opacity: 1; transition: .6s cubic-bezier(0.77, 0, 0.175, 1) .45s width, opacity .1s ease .45s; }
                & > .icon { transform: translateY(-50%) translateX(0); }
                & > .icon::before { transform: translateY(-50%) scaleY(1.4) rotate(0deg); background-color: $mariner; }
                & > .icon svg { fill: white; transform: translateX(-10px); transition: .45s ease .6s fill, .45s ease transform .45s; }
                span { color: white; transition-delay: .75s; }
            }
        }
        &--icon-edge-color {
            height: 60px;
            background-color: transparent;
            padding: 0 50px 0 20px;
            margin-right: 30px;
            box-shadow: inset 0 0 0 1px rgba($hippie-blue, 0.5);
            &::before {
                @include pseudo-element;
                width: 42px;
                height: 100%;
                top: 50%;
                right: 0;
                left: unset;
                transform: translateY(-50%);
                background-color: $mariner;
                opacity: 0;
                transition: .6s ease width, opacity .2s ease;
                border-radius: 2px;
            }
            & > .icon {
                position: absolute;
                height: 42px;
                width: 42px;
                right: 0;
                top: 50%;
                @include flex-element;
                transform: translateY(-50%) translateX(50%);
                transition: .45s ease all;
                &::before {
                    @include pseudo-element;
                    border-radius: 2px;
                    top: 50%;
                    background-color: $mariner;
                    transform: translateY(-50%) rotate(45deg);
                    transition: .45s ease all;
                }
                svg {
                    fill: $bali-hai;
                    transition: .3s ease all;
                }
            }
            span {
                color: $bali-hai;
                letter-spacing: calc(16px * 10 / 100);
            }
            &:hover {
                &::before { width: 100%; opacity: 1; transition: .6s cubic-bezier(0.77, 0, 0.175, 1) .45s width, opacity .1s ease .45s; }
                & > .icon { transform: translateY(-50%) translateX(0); }
                & > .icon::before { transform: translateY(-50%) scaleY(1.4) rotate(0deg); background-color: $mariner; }
                & > .icon svg { fill: white; transform: translateX(-10px); transition: .45s ease .6s fill, .45s ease transform .45s; }
                span { color: white; transition-delay: .75s; }
            }
        }
        &--icon-single {
            width: 45px;
            height: 45px;
            padding: 0;
            @include flex-element;
            margin-right: 12px;
            &::before {
                @include pseudo-element;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                border: 1px solid rgba($hippie-blue, 0.5);
                background-color: $mariner;
                border-radius: 2px;
                transition: .45s ease all;
                will-change: transform, background-color;
                backface-visibility: hidden;
            }
            svg {
                fill: white;
                transition: unset!important;
            }
            &:hover {
                &::before {
                    border-color: transparent;
                    background-color: $orange;
                    transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(1.15);
                }
            }
        }
        &--default {
            background-color: $firefly;
            border-radius: 2px;
            padding: 0 45px;
            &::before {
                @include pseudo-element;
                width: 0;
                height: 100%;
                top: 50%;
                right: 0;
                left: unset;
                transform: translateY(-50%);
                background-color: $mariner;
                opacity: 0;
                transition: .6s ease all;
                border-radius: 2px;
                will-change: transform, opacity;
                backface-visibility: hidden;
            }
            span {
                color: white;
                letter-spacing: calc(18px * 7 / 100);
            }
            svg {
                fill: $mariner;
                transition: .3s ease fill;
            }
            &:hover {
                &::before { width: 100%; opacity: 1; transition: .6s cubic-bezier(0.77, 0, 0.175, 1) all; }
                svg { fill: white; }
            }
        }
        &--default-border {
            height: 70px;
            box-shadow: inset 0 0 0 1px #142635;
            border-radius: 2px;
            padding: 0 30px;
            &::before {
                @include pseudo-element;
                width: 0;
                height: 100%;
                top: 50%;
                right: 0;
                left: unset;
                transform: translateY(-50%);
                background-color: $mariner;
                opacity: 0;
                transition: .6s ease all;
                border-radius: 2px;
                will-change: transform, opacity;
                backface-visibility: hidden;
            }
            span {
                color: white;
                letter-spacing: calc(18px * 7 / 100);
            }
            svg {
                fill: $mariner;
                transition: .3s ease fill;
            }
            &:hover {
                &::before { width: 100%; opacity: 1; transition: .6s cubic-bezier(0.77, 0, 0.175, 1) all; }
                svg { fill: white; }
            }
        }
    }
}