.contact-field {
    position: relative;
    margin-top: 90px;
    &.hide-logo {
        margin-top: 0!important;
        .info-field {
            .logo {
                display: none!important;
            }
        }
    }
    .info-field {
        position: relative;
        .logo {
            position: absolute;
            left: 0;
            bottom: calc(100% + 45px);
            max-width: 175px;
        }
        .headline {
            color: white;
            line-height: 1.25;
        }
        .info-contact {
            display: grid;
            grid-template-columns: repeat(3, minmax(min-content, auto));
            grid-gap: 75px;
            .item {
                display: block;
                .heading {
                    display: flex;
                    align-items: center;
                    svg {
                        width: 16px;
                        height: 16px;
                        margin-right: 15px;
                        flex-shrink: 0;
                        fill: $bali-hai;
                        transition: .45s ease fill;
                        transform-box: fill-box;
                        path {
                            transform-box: fill-box;
                        }
                        &.icon--phone {
                            path { transform-origin: 50% 50%; }
                        }
                    }
                    .title {
                        color: $bali-hai;
                        font-weight: 500;
                        white-space: nowrap;
                        position: relative;
                        .actual {
                            color: $orange;
                        }
                    }
                }
                .value {
                    color: white;
                    white-space: nowrap;
                    margin-top: 5px;
                }
                &:hover { 
                    .heading svg { fill: $orange; }
                }
            }
        }
        .info-working-hours {
            display: flex;
            align-items: center;
            width: fit-content;
            cursor: pointer;
            svg {
                width: 30px;
                height: 30px;
                fill: $bali-hai;
                margin-right: 15px;
                transition: .45s ease fill;
            }
            .value {
                display: block;
                color: white;
                white-space: nowrap;
                position: relative;
                .actual {
                    color: $orange;
                }
            }
            &:hover {
                svg {
                    fill: $orange;
                }
            }
        }
        .info-address {
            display: grid;
            grid-template-columns: repeat(2, minmax(min-content, auto));
            grid-gap: 60px;
            align-items: center;
            .item {
                position: relative;
                display: block;
                span {
                    &.placeholder {
                        color: $bali-hai;
                    }
                    &.actual {
                        color: white;
                    }
                }
            }
        }
        .info-links {
            display: flex;
            align-items: center;
            @include supports(gap, 30px, margin-left, 30px);
            .item {
                position: relative;
                display: block;
                white-space: nowrap;
                & + .item {
                    &::before {
                        content: '|';
                        position: absolute;
                        top: 50%;
                        left: -15px;
                        display: block;
                        flex-shrink: 0;
                        transform: translateY(-50%);
                        color: $blue-dianne;
                    }
                }
                span {
                    &.placeholder {
                        color: $blue-dianne;
                    }
                    &.actual {
                        color: $bali-hai
                    }
                }
            }
        }
    }
    .form-field {
        .expo {
            color: $bali-hai;
        }
    }
    .texture {
        @include safari-overflow;
        position: absolute;
        bottom: 0;
        left: var(--header-width);
        width: calc(100% - var(--header-width));
        height: 50%;
        opacity: 0.2;
        z-index: -1;
        pointer-events: none;
        mask-image: linear-gradient(0deg, black 0%, transparent 75%);
        canvas {
            @include absolute-element;
        }
        img {
            opacity: 0;
            @include image-fit(cover, center 75%);
        }
    }
}