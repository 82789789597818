.service-prolog {
    @include safari-overflow;
    height: 100vh;
    background-color: $ebony;
    position: relative;
    .background {
        @include safari-overflow;
        @include absolute-element;
        mix-blend-mode: exclusion;
        pointer-events: none;
        opacity: 0.05;
        img, video {
            @include image-fit;
        }
    }
    .content {
        position: relative;
        height: 100%;
        & > .wrapper {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .title {
                color: $bali-hai;
            }
            .sub-title {
                color: white;
                line-height: 1.25;
            }
            .expo {
                color: $bali-hai;
                max-width: 600px;
            }
        }
    }
    .image {
        position: absolute;
        backface-visibility: hidden;
        will-change: transform;
        height: 66%;
        top: 50%;
        left: 50%;
        transform: translateX(50%) translateY(-50%) rotate(45deg);
        .shadow {
            @include absolute-element;
            transform: scale(1.5) rotate(-45deg);
            .circle-wrapper { clip-path: url(#clip-path); }
            .circle { fill: url(#circle-gradient); }
            .line { fill: none; stroke: none; stroke-miterlimit: 10; stroke-width: 2px; }
            .clip-path-content { fill: none; }
        }
        &::after {
            @include pseudo-element;
            background: radial-gradient(circle at 50% 50%, rgba($ebony, 0) 0%, rgba($ebony, 0.75) 100%);
            mix-blend-mode: multiply;
        }
        .wrapper {
            @include absolute-element;
            @include safari-overflow;
        }
        img {
            @include image-fit;
        }
    }
    .scroll-down {
        left: 60%;
        top: 85%;
        transform: translateX(-50%) translateY(-50%);        
    }
}

.service-process {
    will-change: position, height, width, transform;
    .wrapper {
        height: 100%;
        @include flex-element;
        position: relative;
        width: fit-content;
        margin: 0 auto;
        .background {
            pointer-events: none;
            height: 100%;
            .fill {
                fill: rgba($hippie-blue, 0.2)
            }
            .stroke {
                fill: none;
                stroke: $christine;
                stroke-miterlimit: 10;
            }
        }
        .content {
            @include absolute-element;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            @include flex-element;
            flex-direction: column;
            max-width: 45%;
            max-height: 60%;
            .text {
                color: white;
                text-align: center;
            }
            .item {
                @include absolute-element;
                @include flex-element;
                flex-direction: column;
            }
        }
        .corners {
            pointer-events: none;
            .item {
                position: absolute;
                width: 90px;
                height: 90px;
                @include flex-element;
                span {
                    position: relative;
                    z-index: 1;
                    display: block;
                    text-align: center;
                    color: white;
                    line-height: 1.25;
                }
                &::before {
                    @include pseudo-element;
                    background-color: $christine;
                    transform: rotate(45deg);
                }
                &:nth-child(1) {
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%) translateY(15px);
                }
                &:nth-child(2) {
                    right: 0;
                    top: 50%;
                    transform: translateX(15px) translateY(-50%);
                }
                &:nth-child(3) {
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%) translateY(-15px);
                }
                &:nth-child(4) {
                    left: 0;
                    top: 50%;
                    transform: translateX(-15px) translateY(-50%);
                }
            }
        }
    }
}

.service-detail {
    position: relative;
    .texture {
        @include safari-overflow;
        position: absolute;
        bottom: 0;
        left: var(--header-width);
        width: calc(100% - var(--header-width));
        height: 100%;
        max-height: 600px;
        opacity: 0.3;
        z-index: -1;
        pointer-events: none;
        mask-image: linear-gradient(0deg, black 0%, transparent 75%);
        canvas {
            @include absolute-element;
            transform: scale(1.25) translateY(-15%);
        }
        img {
            opacity: 0;
            @include image-fit(cover, center 100%);
        }
    }
    .container {
        display: grid;
        grid-template-columns: minmax(0, 1.5fr) minmax(0, 1fr);
        grid-gap: 180px;
        .detail {
            .title {
                line-height: 1.25;
                color: white;
            }
            .list {
                .item {
                    color: white;
                    position: relative;
                    padding-left: 45px;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 22px;
                        height: 22px;
                        @include background-image('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMy4yNyAyMi42OCI+PHBhdGggY2xhc3M9ImQiIGQ9Ik0yLjQyLDEwLjM2Yy0uNDYsMC0uOTEsLjEzLTEuMjksLjM4LS4zOSwuMjUtLjcsLjYtLjg5LDEuMDEtLjIsLjQxLS4yNywuODctLjIyLDEuMzMsLjA1LC40NSwuMjQsLjg4LC41MywxLjI0bDYuMTYsNy41NWMuMjIsLjI3LC41LC40OSwuODIsLjYzcy42NywuMjEsMS4wMiwuMTljLjc1LS4wNCwxLjQyLS40NCwxLjg1LTEuMUwyMy4yLC45N3MwLDAsMC0uMDFjLjEyLS4xOCwuMDgtLjU1LS4xNy0uNzgtLjA3LS4wNi0uMTUtLjExLS4yNC0uMTQtLjA5LS4wMy0uMTgtLjA0LS4yNy0uMDQtLjA5LDAtLjE4LC4wMy0uMjcsLjA3LS4wOCwuMDQtLjE2LC4xLS4yMSwuMTcsMCwwLDAsLjAxLS4wMSwuMDJMOS4xMywxNC44NWMtLjA1LC4wNi0uMTEsLjEtLjE4LC4xMy0uMDcsLjAzLS4xNCwuMDUtLjIxLC4wNS0uMDcsMC0uMTUsMC0uMjItLjAzLS4wNy0uMDItLjEzLS4wNi0uMTktLjExbC00LjI4LTMuOWMtLjQ0LS40MS0xLjAzLS42My0xLjYzLS42M1oiLz48L3N2Zz4=');
                        filter: $filter-mariner;
                    }
                    & + .item {
                        margin-top: 20px;
                    }
                }
            }
        }
        .owner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: -45px;
                top: 0;
                height: 100%;
                width: 1px;
                background-color: rgba($bali-hai, 0.12);
            }
            .text {
                color: $bali-hai;
                font-style: italic;
            }
            .naming {
                color: white;
                .position {
                    letter-spacing: calc(14px * 15 / 100);
                }
            }
        }
    }
}