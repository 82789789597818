@media only screen and (max-width: 900px) {
    .service-prolog .content > .wrapper .sub-title { font-size: 26px; }
    .service-prolog .content > .wrapper .expo { font-size: 18px; }
    .service-prolog .content > .wrapper .sub-title { margin-bottom: 30px!important; }
    .service-detail .container .detail .title { font-size: 24px; }
    .service-detail .container .owner .text { font-size: 18px; }
    .service-detail .container .detail .list .item { font-size: 16px; padding-left: 30px; }
    .service-detail .container { grid-gap: 90px; }
    .services-carousel-wrapper .container { grid-gap: 45px; }
    .services-carousel-wrapper .container .services-image-carousel .swiper-wrapper .swiper-slide .image { height: 45%; }
    .contact-field .container { grid-template-columns: minmax(0, 1fr); grid-gap: 45px; }
    .container { padding: 0 30px 0 105px; }
    .service-prolog .scroll-down { left: 70%; }
    .scroll-down { width: 60px; height: 60px; }
    .scroll-down > span { transform: translateY(30%); font-size: 8px }
    .scroll-down::before { height: 6px; }
    .service-process .wrapper .content .text { font-size: 16px; }
    .services-carousel-wrapper { height: unset!important; }
    .services-carousel-wrapper .container .services-image-carousel { height: 500px; }
    .services-carousel-wrapper .container .services-image-carousel .swiper-wrapper .swiper-slide .image { height: 60%; }
    .contact-field .info-field .headline { font-size: 24px; margin-bottom: 30px!important; }
    .form .item input:not([type=checkbox]), .form .item textarea, .form .item select { font-size: 16px; }
    .service-detail { margin-bottom: 0!important; }
    .about-prolog .content { grid-template-columns: minmax(0, 1fr); }
    .about-prolog .content .scroll-down { display: none; }
    .about-prolog .content .wrapper .title { font-size: 30px; }
    .about-prolog .content .wrapper .expo { font-size: 18px; }
    .about-whatwedo .container .detail .context .list .item { font-size: 16px; }
    .product-features .list .split { margin: 20px 0; }
    .product-prolog .gallery-trigger { width: 60px; height: 60px; }
    .product-prolog .gallery-trigger .wrapper svg { width: 20px; height: 20px; }
    .product-prolog .gallery-trigger .text { font-size: 14px; }
    .product-prolog .gallery-trigger .wrapper::before { transform: scale(1.1); }
    .index-about .container .text .title { font-size: 36px; }
    .index-about .container .text .expo { font-size: 18px; margin-left: 60px; }
    .index-about .container .text .expo::before { right: calc(100% + 30px); width: 30px; }
    .index-products .container .wrapper { height: 80%; }
    .index-products .container .wrapper .content .context .reveal-products .product-list { grid-template-columns: repeat(2, minmax(0, 1fr)); max-width: 300px; }
    .index-products { margin-top: 120px; }
}