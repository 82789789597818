/* [------------------] Static [------------------] */

    @import 'static/variables';
    @import 'static/mixin';
    @import 'static/reset';
    @import 'static/fonts';
    @import 'static/container';
    @import 'static/penta-logo';

/* [------------------] Styling [------------------] */

    @import 'styling/keyframes';
    @import 'styling/typography';
    @import 'styling/scrollbar';
    @import 'styling/editor';
    @import 'styling/flex';
    @import 'styling/grid';
    @import 'styling/alignment';
    @import 'styling/sizing';
    @import 'styling/spacing';
    @import 'styling/button';
    @import 'styling/form';
