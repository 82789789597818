@media only screen and (max-width: 1680px) {
    .service-process .wrapper .content .text { font-size: 24px; }
    .service-prolog .content > .wrapper .sub-title { font-size: 46px; }
    .services-carousel-wrapper .container .services-text-carousel .item .title { font-size: 60px; }
    .services-carousel-wrapper .container { grid-gap: 90px; }
    .contact-field .container { grid-gap: 90px; }
    .about-whatwedo .container .text .title { font-size: 55px; }
    .about-whatwedo .container .detail .context .title { font-size: 46px; }
    .about-whatwedo .container .detail .context .list .item { font-size: 24px; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .title { font-size: 44px; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .expo { font-size: 24px; }
    .services-carousel-wrapper { padding-top: 0!important; }
}