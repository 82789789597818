@media only screen and (max-width: 1200px) {
    .services-carousel-wrapper .container .services-text-carousel .item .title { font-size: 30px; }
    .service-detail .container .detail .title { font-size: 30px; }
    .service-detail .container .owner .text { font-size: 20px; }
    .service-detail .container .detail .list .item { font-size: 18px; }
    .contact-field .info-field .headline { font-size: 28px; }
    .contact-field .info-field .info-address { grid-template-columns: minmax(0, 1fr); grid-gap: 30px; }
    .services-carousel-wrapper .container .services-image-carousel .carousel-nav .prev, .services-carousel-wrapper .container .services-image-carousel .carousel-nav .next { width: 45px; height: 45px; }
    .services-carousel-wrapper .container .services-image-carousel .carousel-nav { left: 77%; top: 82%; }
    .contact-field .info-field .info-contact { grid-gap: 30px; }
    .about-prolog .content .wrapper .expo { margin-left: 150px; }
    .about-prolog .content .wrapper .expo::before { width: 105px; }
    .about-whatwedo .container { grid-gap: 30px; }
    .about-whatwedo .container .detail .image { height: 70%; }
    .about-whatwedo .container .text .title { font-size: 40px; }
    .about-whatwedo { margin-bottom: 0!important; padding-top: 30px!important; padding-bottom: 30px!important; }
    .reference-field .reference-list { grid-template-columns: repeat(3, minmax(0, 1fr)); }
    .product-features .list { font-size: 20px; }
    .product-prolog .background .image:nth-child(2), .product-prolog .background .image:nth-child(3) { opacity: 0.5; height: 35%; }
    .product-prolog .background .image:nth-child(1) { height: 55%; }
    .hero .content .text-area .hero-thumbs-carousel { display: none; }
    .hero .content .text-area { justify-content: center; }
    .hero .content .image-area .hero-image-carousel .swiper-wrapper .swiper-slide .image { height: 60%; }
    .hero .scroll-down { left: 79.5%; }
    .index-about .container .text .title { font-size: 36px; }
    .index-about .container .text .expo { font-size: 18px; }
}