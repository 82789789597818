@media only screen and (max-width: 1440px) {
    :root { --header-height: 550px; }
    .container { padding: 0 105px; }
    .services-carousel-wrapper .container .services-text-carousel .item .title { font-size: 50px; }
    .service-detail .container .detail .title { font-size: 42px; }
    .contact-field .info-field .headline { font-size: 40px; }
    .about-prolog .content .wrapper .title { font-size: 44px; }
    .about-prolog .content .wrapper .expo { font-size: 20px; margin-left: 295px; }
    .about-prolog .content .wrapper .expo::before { width: 250px; }
    .product-prolog .content .title { font-size: 44px; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .title { font-size: 40px; }
    .hero .content .text-area .hero-content-carousel .swiper-slide .item .expo { font-size: 20px; }
    .hero .content .text-area .hero-thumbs-carousel .swiper-wrapper .swiper-slide .item .expo { flex: 0 0 40%; }
    .hero .content .text-area .hero-thumbs-carousel .swiper-wrapper .swiper-slide .item .title { flex: 0 0 40%; }
    .index-about .container .text .title { font-size: 46px; }
    .index-about .container .text .expo { font-size: 22px; }
    .index-products .container .wrapper .content .context .heading .title { font-size: 30px; }
    .index-products .container .wrapper .content .context .heading { max-width: 75%; }
    .index-products .container .wrapper .content .context .reveal-products { margin-top: 0!important; }
    .index-products .container .wrapper .content .context .reveal-products .product-list { padding: 20px; max-width: 425px; grid-gap: 10px; }
    .index-products .container .wrapper .content .context .reveal-products .product-list .item::before { display: none; content: unset; }
    .index-products .container .wrapper .content .context .reveal-products .product-list .item { line-height: 1.25; align-self: center; }
}