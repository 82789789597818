// Height | [USAGE = height--300]
@for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; .height--#{$i} { height: $value!important; } } }
@media only screen and (max-width: 1680px) { @for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; @if $i > 550 { .height--#{$i} { height: 550px!important; } } } } }
@media only screen and (max-width: 1440px) { @for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; @if $i > 450 { .height--#{$i} { height: 450px!important; } } } } }
@media only screen and (max-width: 1366px) { @for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; @if $i > 350 { .height--#{$i} { height: 350px!important; } } } } }
@media only screen and (max-width: 1024px) { @for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; @if $i > 300 { .height--#{$i} { height: 300px!important; } } } } }
@media only screen and (max-width: 768px) { @for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; @if $i > 275 { .height--#{$i} { height: 275px!important; } } } } }
@media only screen and (max-width: 480px) { @for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; @if $i > 225 { .height--#{$i} { height: 225px!important; } } } } }
@media only screen and (max-width: 375px) { @for $i from 50 through 750 { @if $i % 25 == 0 { $value: $i * 1px; @if $i > 200 { .height--#{$i} { height: 200px!important; } } } } }

// Height as Percent | [USAGE = height-percent--50]
@for $i from 0 through 100 { $value: $i * 1%; .height-percent--#{$i} { height: $value!important; } }

// Width as Percent | [USAGE = width-percent--50]
@for $i from 0 through 100 { $value: $i * 1%; .width-percent--#{$i} { width: $value!important; } }

.height--fit { height: fit-content!important; }
.width--fit { width: fit-content!important; }