.hero {
    display: flex;
    align-items: center;
    position: relative;
    @include safari-overflow;
    .background {
        @include safari-overflow;
        @include absolute-element;
        mix-blend-mode: exclusion;
        pointer-events: none;
        opacity: 0.05;
        img, video {
            @include image-fit;
        }
    }
    .scroll-down {
        top: 80%;
        left: 79.5%;
        z-index: 5;
    }
    .content {
        height: 100%;
        position: relative;
        z-index: 2;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .text-area {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .hero-content-carousel {
                margin-left: 0;
                max-width: 475px;
                overflow: visible;
                position: relative;
                z-index: 5;
                .swiper-slide {
                    .item {
                        opacity: 0;
                        visibility: hidden;
                        transition: .6s ease all;
                        pointer-events: none;
                        transform: scale(0.75);
                        will-change: transform, opacity;
                        .title {
                            color: white;
                            line-height: 1.1;
                            @include multi-line-limit(4);
                        }
                        .expo {
                            color: $bali-hai;
                            @include multi-line-limit(3);
                        }
                    }
                }
                .swiper-slide-active {
                    .item {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: visible;
                        transform: scale(1);
                    }
                }
            }
            .hero-thumbs-carousel {
                margin-left: 0;
                width: calc(100% - 75px);
                overflow: visible;
                height: 70px;
                margin-bottom: 80px;
                .swiper-wrapper {
                    .swiper-slide {
                        height: 70px;
                        pointer-events: none;
                        &.swiper-slide-active {
                            pointer-events: visible;
                            .item {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                                transition: .6s cubic-bezier(0.77, 0, 0.175, 1) all;
                            }
                        }
                        &.swiper-slide-next {
                            pointer-events: visible;
                            .item {
                                opacity: 0.5;
                                visibility: visible;
                                transform: scale(1);
                                transition: .45s ease all;
                            }
                        }
                        .item {
                            cursor: pointer;
                            opacity: 0;
                            visibility: hidden;
                            transform: scale(0.8);
                            transition: .45s ease all;
                            display: flex;
                            justify-content: space-between;
                            position: relative;
                            padding-top: 15px;
                            &:hover {
                                opacity: 1!important;
                            }
                            .progress {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                background-color: rgba($bali-hai, 0.3);
                                .fill {
                                    width: 100%;
                                    height: 100%;
                                    background-color: $mariner;
                                    transform: scaleX(0);
                                    transform-origin: 0% 0%;
                                }
                            }
                            .number {
                                flex: 0 0 20%;
                                width: 120px;
                                color: white;
                                flex-shrink: 0;
                            }
                            .title {
                                flex: 0 0 50%;
                                justify-self: flex-end;
                                color: $bali-hai;
                                text-align: right;
                            }
                            .expo {
                                flex: 0 0 30%;
                                color: white;
                                line-height: 1.25;
                                max-width: 250px;
                            }
                        }
                    }
                }
            }
        }
        .image-area {
            height: 100%;
            .hero-image-carousel {
                overflow: visible;
                height: 100%;
                .swiper-wrapper {
                    height: 100%;
                    .swiper-slide {
                        height: 100%;
                        overflow: visible;
                        backface-visibility: hidden;
                        .image {
                            backface-visibility: hidden;
                            height: 70%;
                            position: relative;
                            transform: rotate(45deg);
                            .wrapper {
                                @include safari-overflow;
                                @include absolute-element;
                                backface-visibility: hidden;
                                img, video {
                                    backface-visibility: hidden;
                                    @include image-fit;
                                    transform: scale(1.5) rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .navigation {
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 6;
        transform: translateX(-50%);
        background: linear-gradient(0deg, rgba($hippie-blue, 0.1) 0%, rgba($hippie-blue, 0) 100%);
        padding: 10px 15px;
        display: flex;
        align-items: center;
        @include supports(gap, 15px, margin-left, 15px);
        .prev, .next {
            cursor: pointer;
            flex-shrink: 0;
            border-radius: 3px;
            box-shadow: 0 0 0 2px rgba($hippie-blue, 0.5);
            width: 18px;
            height: 18px;
            @include flex-element;
            transition: .3s ease all;
            &.swiper-button-disabled {
                opacity: 0.5;
                pointer-events: none;
            }
            &:hover {
                background-color: rgba($hippie-blue, 0.5);
                svg {
                    fill: white;
                }
            }
            svg {
                width: 10px;
                fill: rgba($hippie-blue, 0.5);
                transition: .3s ease all;
            }
        }
    }
}

.index-about {
    position: relative;
    @include safari-overflow;
    background-color: $ebony;
    .container {
        .text {
            max-width: 1024px;
            position: relative;
            z-index: 2;
            .sub-title {
                color: $bali-hai;
                letter-spacing: calc(18px * 22 / 100);
            }
            .title {
                color: white;
                line-height: 1.25;
            }
            .expo {
                margin-left: 180px;
                position: relative;
                color: $bali-hai;
                &::before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 150px;
                    right: calc(100% + 45px);
                    top: 15px;
                    background-color: $christine;
                }
            }
            .slogan-field {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .text {
                    color: white;
                    strong {
                        font-weight: 500;
                        font-style: italic;
                        color: $christine;
                    }
                }
            }
        }
        .image {
            position: absolute;
            right: 0;
            top: 50%;
            height: 70%;
            transform: translateY(-50%) rotate(45deg);
            opacity: 0.5;
            .wrapper {
                @include safari-overflow;
                @include absolute-element;
                &::after {
                    @include pseudo-element;
                    background: radial-gradient(circle at 50% 50%, rgba($ebony, 0) 0%, rgba($ebony, 0.75) 100%);
                    mix-blend-mode: multiply;
                }
                img, video {
                    @include image-fit;
                    transform: scale(1.5) rotate(-45deg);
                }
            }
        }
    }
}

.index-products {
    .container {
        height: 100%;
        background-color: $ebony;
        .wrapper {
            mix-blend-mode: exclusion;
            margin: 0 auto;
            @include safari-overflow;
            height: 90%;
            transform: rotate(45deg);
            background: linear-gradient(135deg, rgba($mariner, 0.3) 0%, rgba($mariner, 0) 100%);
            .content {
                width: 100%;
                height: 100%;
                transform: rotate(-45deg);
                background-color: transparent;
                .images {
                    position: absolute;
                    width: 100%;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-25%);
                    opacity: 0.1;
                    mix-blend-mode: lighten;
                    .image {
                        @include safari-overflow;
                        width: 50%;
                        transform: rotate(45deg);
                        img {
                            @include image-fit;
                            transform: scale(1.5) rotate(-45deg);
                        }
                        & + .image {
                            margin-top: 210px;
                        }
                    }
                }
                .context {
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                   
                    .heading {
                        max-width: 50%;
                        margin: 0 auto;
                        text-align: center;
                        .sub-title {
                            color: $bali-hai;
                            letter-spacing: calc(18px * 22 / 100);
                        }
                        .title {
                            color: white;
                            line-height: 1.25;
                        }
                    }
                    .reveal-products {
                        &.active {
                            .button {
                                &.button-style--icon-edge-color::before { background-color: $mariner; }
                                &.button-style--icon-edge-color > .icon::before { background-color: $mariner; }
                                &.button-style--icon-edge-color > .icon svg { transform: rotate(0deg); }
                            }
                        }
                        .button {
                            margin: 0 auto;
                            width: fit-content;
                            &.button-style--icon-edge-color::before { background-color: $christine; }
                            &.button-style--icon-edge-color > .icon::before { background-color: $christine; }
                            &.button-style--icon-edge-color > .icon svg { transform: rotate(45deg); fill: white; }
                            &.button-style--icon-edge-color > span { color: white; }
                        }
                        .product-list {
                            max-width: 550px;
                            margin: 0 auto;
                            display: grid;
                            grid-template-columns: repeat(3, minmax(0, 1fr));
                            grid-gap: 15px 30px;
                            background: linear-gradient(180deg, rgba(#9CA5C0, 0.6) 0%, rgba(#9CA5C0, 0) 100%);
                            border-style: solid;
                            border-image-slice: 1;
                            border-width: 1px;
                            border-radius: 2px;
                            padding: 45px;
                            border-image-source: linear-gradient(180deg, rgba($hippie-blue, 0.15) 0%, rgba($hippie-blue, 0) 100%);
                            mask-image: linear-gradient(180deg, black 0%, black calc(100% - 10px), transparent 100%);
                            .item {
                                display: block;
                                position: relative;
                                width: fit-content;
                                .actual { color: white; }
                                .placeholder { color: rgba(white, 0.8) }
                                &::before {
                                    content: '';
                                    position: absolute;
                                    right: calc(100% + 12px);
                                    width: 4px;
                                    height: 4px;
                                    background-color: $christine;
                                    top: 50%;
                                    transform: translateY(-50%) rotate(45deg);
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }
}